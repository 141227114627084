import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import SEO from '../components/seo'

import Post from '../components/Post/Post'

const BlogPage = ({ data }) => {
  return (
    <>
      <SEO title="Blog | BluePes" keywords={[`bluepes`, `blog`, `articles`, `IT`]} />
      <section className="new-blog-page">
        <div className="general-page-wrapper">
          <article className="newest-article wrapper">
            <a className="newest-article-wrapper" href={data.article.fields.slug}>
              <div className="article-image">
                <BackgroundImage
                  Tag="div"
                  fluid={data.article.frontmatter.image.childImageSharp.fluid}
                />
              </div>
              <div className="article-context">
                <h1 className="article-header">
                  {data.article.frontmatter.title}
                </h1>
                <div
                  className="article-date">
                  {data.article.frontmatter.date}
                </div >
                <div className="article-text"
                  dangerouslySetInnerHTML={{
                    __html: data.article.html
                  }}
                />
                <div className="link-to-article-page">
                      Read more
                </div>
              </div>
            </a>
          </article>
        </div>
        <div className="promoted-wrapper">
          <h2 className="wrapper">Interesting for you</h2>
          <div className="promoted-articles  wrapper">
            {data.promoted.edges.slice(0, 3).map(({ node }) => {
              return (
                <Post
                  key={node.id}
                  title={node.frontmatter.title}
                  slug={node.fields.slug}
                  image={node.frontmatter.image.childImageSharp.fluid}
                  date={node.frontmatter.date}
                />
              )
            })}
          </div>
        </div>
        <div className="blog-articles">
          <div className="blog-articles-wrapper wrapper">
            {data.articles.edges.map(({ node }) => {
              return (
                <Post
                  key={node.id}
                  title={node.frontmatter.title}
                  slug={node.fields.slug}
                  image={node.frontmatter.image.childImageSharp.fluid}
                  date={node.frontmatter.date}
                />
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export const articlesQuery = graphql`
  query ($top: String! $promotedArticles: [String]!) {
    articles: allMarkdownRemark (
      limit: 1000
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {
        fileAbsolutePath: {regex: "/content/blog/"}, 
        fields: { slug: {nin: $promotedArticles, ne: $top}}
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fluid(
                  maxWidth: 456, 
                  maxHeight: 456, 
                  quality: 80, 
                  cropFocus: CENTER,
                  srcSetBreakpoints: [224, 288, 344, 352, 456, 712]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    article:markdownRemark(fields: { slug: { eq: $top }}) {
      id
      html
      excerpt(pruneLength: 250)
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        image {
          childImageSharp {
            fluid(
              maxWidth: 456, 
              maxHeight: 456, 
              quality: 80, 
              cropFocus: CENTER,
              srcSetBreakpoints: [224, 288, 344, 352, 456, 712]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
    promoted: allMarkdownRemark(
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {
        fileAbsolutePath: {regex: "/content/blog/"}, 
        fields: {slug: {in: $promotedArticles}}}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fluid(
                  maxWidth: 456, 
                  maxHeight: 456, 
                  quality: 80, 
                  cropFocus: CENTER,
                  srcSetBreakpoints: [224, 288, 344, 352, 456, 712]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default BlogPage
