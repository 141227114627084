import React from 'react'
import classes from './Post.module.scss'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

/**
 * Backdrop component
 **/

const Post = ({ title, slug, image, date }) => (
  <article className={classes.BlogArticle}>
    <Link
      to={slug}
      className={classes.BlogArticleLink}>
      <div className={classes.BlogArticleImage}>
        <Img
          fluid={image}
          alt={title} />
      </div>
      <div className={classes.BlogArticleDate}>
        {date}
      </div>
      <div className={classes.BlogArticleHeader}>
        {title}
      </div>
      <div className={classes.LinkToArticlePage}>
          Read more
      </div>
    </Link>
  </article>
)

export default Post
